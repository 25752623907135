import React, {ReactNode, useState} from "react";
import SmallFieldset from "../SmallFieldset";
import {Card} from "@aws-amplify/ui-react";

export interface IGenericCardProps {
    children: ReactNode
    title: string
    onEdit?: (evt: React.MouseEvent<HTMLParagraphElement>) => void
    onSave?: (evt: React.MouseEvent<HTMLParagraphElement>) => void
}

const GenericCard = ({title, children, onEdit, onSave}: IGenericCardProps) => {
    const [isEdit, setIsEdit] = useState<boolean>(true);

    const renderEditLink = () => {
        return (
            <p className={"text-sm text-gray-800 hover:cursor-pointer hover:text-red-500"}
               onClick={evt => {
                   setIsEdit(false);
                   onEdit && onEdit(evt)
               }}>
                EDIT
            </p>
        )
    }

    const renderSaveLink = () => {
        return (
            <p className={"text-sm text-gray-800 hover:cursor-pointer hover:text-red-500"}
               onClick={evt => {
                   setIsEdit(true);
                   onSave && onSave(evt)
               }}>
                SAVE
            </p>
        )
    }

    const renderLink = () => {
        return isEdit ? renderEditLink() : renderSaveLink();
        //return null
    }


    return (
        <div className={"flex flex-col p-2 shadow-md rounded"}>
            <div className={"flex justify-between"}>
                <h6 className={"text-md font-semibold font-mono uppercase text-gray-700 mb-2"}>{title}</h6>
                {renderLink()}
            </div>
            <div>
                {children}
            </div>

        </div>
    );
}

export default GenericCard;
import {Cat, IGenericOk, IMedicalRecordEntry, IPrice, IShelter, IVet} from "./Types";

const ENDPOINT: string = "https://api.arc.raxware.com"
//const ENDPOINT = "";

const ApiService = {
    _get: (path: string): Promise<any> => {
        return fetch(`${ENDPOINT}${path}`, {
            mode: 'cors',
            method: 'GET',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json"
            }
        }).then(value => value.json())
    },
    _post: (path: string, data?: any): Promise<any> => {
        let bodyData = null;
        if (data) {
            bodyData = JSON.stringify(data);
        }
        return fetch(`${ENDPOINT}${path}`, {
            mode: 'cors',
            method: 'post',
            cache: 'no-cache',
            headers: {
                "Content-Type": "application/json"
            },
            body: bodyData
        }).then(value => {
            return value.json()
        })
    },
    getCats: (): Promise<any> => {
        return ApiService._post("/v1/list/").then(value => {
            return value as Cat[]
        });
    },
    getCat: (id: string): Promise<Cat> => {
        const searchParams = {"species": "cat", "id": id}
        return ApiService._get("/v1/cat/" + id).then(value => {
            return value as Cat;
        })
    },
    addCat: (newCat: any): Promise<any> => {
        return ApiService._post("/v1/cat/", newCat).then(value => {
            return value;
        })
    },
    getShelters: (): Promise<IShelter[]> => {
        return ApiService._get("/v1/shelter/").then(value => {
            return value as IShelter[]
        });
    },
    addShelter: (name: string): Promise<IGenericOk> => {
        return ApiService._post("/v1/shelter/", {name}).then(value => {
            return value as IGenericOk;
        })
    },
    getVets: (): Promise<IVet[]> => {
        return ApiService._get("/v1/vet/").then(value => {
            return value as IVet[]
        });
    },
    addVet: (name: string): Promise<IGenericOk> => {
        return ApiService._post("/v1/vet/", {name}).then(value => {
            return value as IGenericOk;
        })
    },
    addMedicalRecord: (animalId: string, record: any): Promise<IGenericOk> => {
        return ApiService._post(`/v1/medical/${animalId}`, record).then(value => {
            return value as IGenericOk;
        })
    },
    getMedicalRecords: (animalId: string): Promise<IMedicalRecordEntry[]> => {
        return ApiService._get(`/v1/medical/${animalId}`).then(value => {
            return value as IMedicalRecordEntry[]
        })
    },
    getLogoSvg: (): string => {
        return "https://resources.arc.raxware.com/whisker_haven_logo.svg";
    },
    getPrices: (): Promise<IPrice[]> => {
        const arcPricesKey = window.sessionStorage.getItem("arc.prices");
        if (arcPricesKey === null) {
            return ApiService._get(`/v1/meta/price`).then(value => {
                window.sessionStorage.setItem("arc.prices", JSON.stringify(value))
                return value as IPrice[];
            })
        } else {
            return new Promise<IPrice[]>(resolve => resolve(JSON.parse(arcPricesKey) as IPrice[]))
        }

    }
};

export default ApiService;


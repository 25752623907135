import '@aws-amplify/ui-react/styles.css';
import React, {useEffect} from "react";

import SideNavigation from "./components/SideNavigation";
import {Outlet} from "react-router-dom";
import {ErrorProvider, useError} from "./components/context/ErrorContext";
import DangerAlert from "./components/alerts/DangerAlert";

export function App() {
    const {errorMessage, clearError} = useError();

    return (
        <>
            <div className={" flex justify-between bg-white py-3"}>
                <SideNavigation/>
            </div>

            {errorMessage && (
                <div className={"mx-4 mt-2 mb-0"}>
                    <DangerAlert text={errorMessage} title={"ERROR"} onClose={clearError}/>
                </div>
            )}

            <div
                className={"bg-white mt-8 mb-0 border-gray-500 border-2 rounded-2xl mx-4 lg:mx-20 h-full"}
                id={'arc-app-container'}
            >
                <div className={'flex flex-col'}>
                    <div id={'app-container'} className={'flex flex-grow h-full'}>
                        <div id={'app'} className={'flex-grow h-full'}>
                            <Outlet/>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

//export default withAuthenticator(App);
export default App
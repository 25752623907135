// ErrorContext.tsx
import React, {createContext, useContext, useState, FC, ReactNode} from 'react';

interface ErrorContextType {
    errorMessage: string | null;
    setError: (message: string) => void;
    clearError: () => void;
}

interface IErrorProviderProps {
    children: ReactNode
}

const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

export const useError = () => {
    const context = useContext(ErrorContext);
    if (!context) {
        throw new Error('useError must be used within an ErrorProvider');
    }
    return context;
};

export const ErrorProvider = ({children}: IErrorProviderProps) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const setError = (message: string) => {
        setErrorMessage(message);
        // You can also log the error, send it to a server, etc.
    };

    const clearError = () => {
        setErrorMessage(null);
    };

    const value: ErrorContextType = {
        errorMessage,
        setError,
        clearError,
    };

    return (
        <ErrorContext.Provider value={value}>
            {children}
        </ErrorContext.Provider>
    );
};

import React, {useEffect, useState} from "react";
import {IShelter} from "../../api/Types";
import Loading from "../../components/Loading";
import ApiService from "../../api/Api";
import SmallFieldset from "../../components/SmallFieldset";
import {Button, Divider, Flex, Input, Label} from "@aws-amplify/ui-react";
import {IoAdd, IoCloseCircle, IoTrashBin} from "react-icons/io5";

export interface IListSheltersPageProps {

}

const ListSheltersPage = () => {

    const [shelters, setShelters] = useState<IShelter[]>([]);
    const [newShelterName, setNewShelterName] = useState<string>();
    const [showAddNewForm, setShowAddNewForm] = useState<boolean>(false);

    useEffect(() => {
        ApiService.getShelters().then(value => setShelters(value))
    }, [shelters]);

    const renderShelters = () => {
        return shelters.map((value, index) => {
            return (
                <div key={value.id} className={"flex"}>
                    <Button marginRight={10} size="small" onClick={() => alert("Not yet functional")}>
                        <IoTrashBin/>
                    </Button>
                    <div>{value.name}</div>
                </div>
            )
        })
    }

    const renderAddNewForm = () => {
        return (
            <>
                <Divider marginTop={10}/>
                <div className={"mt-4"}>
                    <h2>Add New Shelter</h2>
                    <Flex direction="column" gap="small">
                        <Label htmlFor="name">
                            Name
                        </Label>
                        <Input
                            id="name"
                            name="name"
                            type="text"
                            onChange={event => setNewShelterName(event.currentTarget.value)}
                            isRequired={true}
                        />
                    </Flex>
                    <Button type="button" onClick={addNewShelter}>Submit</Button>
                </div>
            </>
        )
    }

    const addNewShelter = () => {
        if (newShelterName != null) {
            ApiService.addShelter(newShelterName)
                .then(response => {
                    setShelters([])
                    setShowAddNewForm(false)
                })
        }
    }

    const renderUi = () => {
        return (
            <div>
                <div className={"flex justify-between m-5"}>
                    <h1>Shelters</h1>
                    <Button type="button" onClick={() => setShowAddNewForm(!showAddNewForm)}>
                        {showAddNewForm ? <IoCloseCircle/> : <IoAdd/>}
                    </Button>
                </div>

                {renderShelters()}
                {showAddNewForm && renderAddNewForm()}
            </div>

        )
    }

    return (
        <div>
            {shelters.length === 0 ? <Loading/> : renderUi()}
        </div>
    );
}

export default ListSheltersPage;
type ExoticPetGender = 'MALE' | 'FEMALE' | 'UNKNOWN'


/**
 * Any pet that we are rescuing
 */
export interface IExoticPet {
    hash: string
    name: string
    species: string
    breed: string
    colors: string[]
    gender: ExoticPetGender
    condition: string
    image: IExoticPetImage
    source: any
    dates: Dates
    adoption_status: string
    intake_notes: string
}

export interface IExoticPetImage {
    filename?: string
    type?: 'image/jpeg' | 'image/jpg' | 'image/png'
}

export interface Cat extends IExoticPet {
    age_group: CatAgeGroup
    microchip: Microchip
    rabies: string
    mixBreed: boolean
    spay_neuter: boolean
}

type CatAgeGroup = 'KITTEN' | 'NEWBORN' | 'ADULT' | 'SENIOR'

export interface Dates {
    intake: string
    birth: string
}

export interface Microchip {
    type: string
    number: string
}

export const IntakeTypeArray = ['UNKNOWN', 'STRAY', 'TRANSFER', 'OWNER_SURRENDER', 'BORN_IN_CARE'] as const
export type IntakeType = typeof IntakeTypeArray

export const StrayIntakeTypeArray = ['UNKNOWN', 'FOUND_IN_PUBLIC', 'FOUND_IN_RESCUE'] as const
export type StrayIntakeType = typeof StrayIntakeTypeArray

//type StrayIntakeType = 'PUBLIC' | 'RESCUE' // *Found by


type PetAdoptionStatus =
    'PENDING'
    | 'ADOPTABLE'
    | 'QUARENTINED'
    | 'PENDING TRANSFER'
    | 'PENDING ADOPTION'
    | 'MEDICAL HOLD';

export interface IShelter {
    id: string;
    name: string;
}

export interface IVet {
    id: string;
    name: string;
}

export interface IGenericOk {
    message: string;
    hash: string;
}


export interface IMedicalRecordEntry {
    medicalType: string;
    eventDate: string;
    who: {
        id: string
        name: string
    };
    medicalRecord: any
    notes: string
    timestamp: number
}

export interface IPrice {
    id: string;
    name: string;
    min_days: number;
    max_days: number;
    dollars: number
}

export const DEFAULT_PRICE: IPrice = {
    dollars: 0,
    max_days: 0,
    min_days: 0,
    name: "UNKNOWN",
    id: "UNKNOWN"
}
import React from "react";

export interface IVaccinationSubSectionProps {
    onChange: (medicalRecord: any) => void;
    value: any;
}

const VaccinationSubSection = ({value, onChange}: IVaccinationSubSectionProps) => {
    return (
        <div className="mb-5">
            <label htmlFor="vaccineName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Vaccine Name
            </label>
            <input type="text" id="vaccineName"
                   value={value.name}
                   className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                   onChange={event => onChange({vaccineName: event.currentTarget.value})}
                   required/>
        </div>
    );
}

export default VaccinationSubSection;
import React, {useState} from "react";
import SmallFieldset from "../SmallFieldset";
import GenericCard from "./GenericCard";
import {SelectField} from "@aws-amplify/ui-react";
import {IExoticPet} from "../../api/Types";
import {toPrettyPrint} from "../../utils/GeneralUtils";

export interface IPetAdoptionStatusCardProps {
    animal: IExoticPet
}

const PetIntakeStatusCard = ({animal}: IPetAdoptionStatusCardProps) => {


    console.log(animal.source)

    const determineSubType = () => {
        if (animal.source['primaryType'] === "STRAY") {
            return animal.source['subType']
        } else if (animal.source['subType']['name']) {
            return animal.source['subType']['name']
        } else {
            return null
        }
    }
    return (
        <GenericCard title={"Intake"}>
            <SmallFieldset label={"Type"} text={animal.source['primaryType'] || "UNKNOWN"}/>
            <SmallFieldset label={""}
                           text={toPrettyPrint(determineSubType())}/>
        </GenericCard>
    );
}

export default PetIntakeStatusCard;
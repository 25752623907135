import * as React from 'react';
import {
    Button, Card,
    DropZone,
    Flex, Icon,
    Image,
    Text, View,
    VisuallyHidden,
} from '@aws-amplify/ui-react';
import {FaTrash} from "react-icons/fa";

const acceptedFileTypes = ['image/png', 'image/jpeg'];

interface IFileDropZoneProps {
    onFileUploaded: (file: File[]) => void;
}

const FileDropZone = (props: IFileDropZoneProps) => {
    const [files, setFiles] = React.useState<File[]>([]);
    const hiddenInput = React.useRef<HTMLInputElement>(null);

    const onFilePickerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const uploadedFiles = event.target.files;
        if (!uploadedFiles || uploadedFiles.length === 0) {
            return;
        }

        setFiles(Array.from(uploadedFiles));
        props.onFileUploaded(Array.from(uploadedFiles));
    };

    return (
        <>
            <DropZone
                acceptedFileTypes={acceptedFileTypes}
                onDropComplete={({acceptedFiles, rejectedFiles}) => {
                    setFiles(acceptedFiles);
                }}
            >
                <Flex direction="column" alignItems="center">
                    <Text>Drag images here or</Text>
                    <Button size="small" onClick={() => hiddenInput.current!.click()}>
                        Browse
                    </Button>
                </Flex>
                <VisuallyHidden>
                    <input
                        type="file"
                        tabIndex={-1}
                        ref={hiddenInput}
                        onChange={onFilePickerChange}
                        multiple={true}
                        name={"file-upload"}
                        accept={acceptedFileTypes.join(',')}
                    />
                </VisuallyHidden>
            </DropZone>
            <Flex>
                {files.map((file) => {
                    return (
                        <View key={file.name}>
                            <Card>
                                <Image
                                    src={URL.createObjectURL(file)}
                                    alt={file.name}
                                    width={200}
                                />
                                <Text textAlign={"center"}>{file.name}</Text>
                            </Card>
                        </View>
                    )
                })}
            </Flex>
        </>
    );
}

export default FileDropZone;
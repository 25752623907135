import React from 'react';

export interface ILoadingProps {
    text?: string
}

const Loading = ({text}: ILoadingProps) => {
    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900"></div>
            <p className={'pt-2'}>{text || "Loading..."}</p>
        </div>
    );
};

export default Loading;

import React, {ChangeEvent, useEffect, useState} from "react";
import {Flex, Input, Label, SelectField, SwitchField} from "@aws-amplify/ui-react";
import {IntakeType, IntakeTypeArray, IShelter, StrayIntakeTypeArray} from "../../api/Types";
import ShelterDropdown from "./ShelterDropdown";
import {toEnumString, toPrettyPrint, toTitleCase} from "../../utils/GeneralUtils";

export interface IStatusUpdate {
    primaryType: string
    subType: any
}

export interface IStatusFieldsetProps {
    onChange: (status: IStatusUpdate) => void
}


const StatusFieldset = ({onChange}: IStatusFieldsetProps) => {
    const [primaryType, setPrimaryType] = useState<string>('UNKNOWN');
    const [strayIntakeType, setStrayIntakeType] = useState<string>('');
    const [textInput, setTextInput] = useState<string>("");
    const [transferShelter, setTransferShelter] = useState<IShelter>();

    useEffect(() => {
        // 'UNKNOWN', 'STRAY', 'TRANSFER', 'OWNER_SURRENDER', 'BORN_IN_CARE'
        switch (primaryType) {
            case 'STRAY':
                onChange({primaryType, subType: strayIntakeType});
                break;
            case 'TRANSFER':
                onChange({primaryType, subType: transferShelter})
                break;
            case 'OWNER_SURRENDER':
                onChange({primaryType, subType: textInput})
                break;
            case 'BORN_IN_CARE':
                onChange({primaryType, subType: null})
                break;
        }
    }, [primaryType, textInput, strayIntakeType, transferShelter]);

    const renderSubType = () => {
        switch (primaryType) {
            case 'STRAY':
                return renderStraySubtypes()
            case 'TRANSFER':
                return <ShelterDropdown label={"Transfer Shelter"} onChange={shelter => setTransferShelter(shelter)}/>
            case 'OWNER_SURRENDER':
                return renderTextInput("Owner Surrender")
            case 'BORN_IN_CARE':
                return null
        }
    }

    const renderTextInput = (label: string) => {
        return (
            <Flex direction="column" gap="small">
                <Label htmlFor="animal_status">
                    {label}
                </Label>
                <Input type="text" name={"animal_status"} value={textInput}
                       onChange={event => setTextInput(event.currentTarget.value)}/>
            </Flex>
        )
    }

    const renderStraySubtypes = () => {
        return (
            <Flex direction="column" gap="small">
                <SelectField
                    id={"strayIntakeType"}
                    name={"strayIntakeType"}
                    value={toPrettyPrint(strayIntakeType.toString())}
                    onChange={doChangeStrayIntakeType}
                    label={"Stray Intake Type"}>
                    {
                        StrayIntakeTypeArray.map(value => {
                            return (
                                <option id={value}>{toPrettyPrint(value)}</option>
                            )
                        })
                    }
                </SelectField>
            </Flex>
        )
    }

    const doChangeStrayIntakeType = (evt: ChangeEvent<HTMLSelectElement>) => {
        setStrayIntakeType(evt.currentTarget.selectedOptions[0].getAttribute("id") || "UNKNOWN")
    }

    const doChangePrimaryType = (evt: ChangeEvent<HTMLSelectElement>) => {
        setPrimaryType(evt.currentTarget.selectedOptions[0].getAttribute("id") || "UNKNOWN")
    }


    return (
        <>
            <Flex direction="column" gap="small">
                <SelectField
                    id={"intakeType"}
                    name={"intakeType"}
                    value={toPrettyPrint(primaryType)}
                    onChange={doChangePrimaryType}
                    label={"Intake Type"}>
                    {
                        IntakeTypeArray.map(value => {
                            return (
                                <option id={value}>{toPrettyPrint(value)}</option>
                            )
                        })
                    }
                </SelectField>
            </Flex>
            {renderSubType()}
        </>
    );
}

export default StatusFieldset;
import React, {useEffect, useState} from "react";
import Loading from "./Loading";
import {Link} from "react-router-dom";
import {Button, ButtonGroup, Flex} from "@aws-amplify/ui-react";
import ApiService from "../api/Api";

export interface IVariahubApp {
    hash: string;
    label: string;
    name: string;
    url: string;
}

export interface ISideNavigationProps {
}

export interface IAppDetails {
    version: string;
    apps: IAppDetail[]
}

export interface IAppDetail {
    hash: string;
    label: string;
}

const SideNavigation = ({}: ISideNavigationProps): React.ReactElement => {

    const renderSection = (sectionTitle: string) => {
        return (
            <li className="px-5">
                <div className="flex flex-row items-center h-8">
                    <div className="text-sm font-light tracking-wide text-gray-500">{sectionTitle}</div>
                </div>
            </li>
        )
    }

    const renderSubSectionLink = (label: string, path: string, tag?: string, tagColor?: string) => {
        return (
            <li>

                <Link to={path}
                      className="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-indigo-500 pr-6">
                    {/*<span className="inline-flex justify-center items-center ml-4">*/}
                    {/*  {icon}*/}
                    {/*</span>*/}
                    <span className="ml-2 text-sm tracking-wide truncate">{label}</span>
                    {tag && <span
                        className={`px-2 py-0.5 ml-auto text-xs font-medium tracking-wide text${tagColor}-500 bg-${tagColor}-50 rounded-full`}>{tag}</span>}
                </Link>
            </li>
        )
    }

    return (
        <div className="flex justify-between overflow-y-auto overflow-x-hidden flex-grow">
            <Link to={"/"}><img src={ApiService.getLogoSvg()} className={"max-w-24"}/></Link>
            <ul className="flex py-4">
                {renderSubSectionLink("Cats", "/cats")}
                {renderSubSectionLink("Add Cat", "/cats/add")}
                {renderSubSectionLink("Shelters", "/shelters")}
                {renderSubSectionLink("Vets", "/vets")}
            </ul>
        </div>
    )
}

export default SideNavigation;
import React, {useEffect, useState} from "react";
import {IVet} from "../../api/Types";
import Loading from "../../components/Loading";
import ApiService from "../../api/Api";
import SmallFieldset from "../../components/SmallFieldset";
import {Button, Divider, Flex, Input, Label} from "@aws-amplify/ui-react";
import {IoAdd, IoCloseCircle, IoTrashBin} from "react-icons/io5";

export interface IListVetsPageProps {

}

const ListVetsPage = () => {

    const [vets, setVets] = useState<IVet[]>([]);
    const [newVetName, setNewVetName] = useState<string>();
    const [showAddNewForm, setShowAddNewForm] = useState<boolean>(false);

    useEffect(() => {
        ApiService.getVets().then(value => setVets(value))
    }, [vets]);

    const renderVets = () => {
        return vets.map((value, index) => {
            return (
                <div key={value.id} className={"flex"}>
                    <Button marginRight={10} size="small" onClick={() => alert("Not yet functional")}>
                        <IoTrashBin/>
                    </Button>
                    <div>{value.name}</div>
                </div>
            )
        })
    }

    const renderAddNewForm = () => {
        return (
            <>
                <Divider marginTop={10}/>
                <div className={"mt-4"}>
                    <h2>Add New Vet</h2>
                    <Flex direction="column" gap="small">
                        <Label htmlFor="name">
                            Name
                        </Label>
                        <Input
                            id="name"
                            name="name"
                            type="text"
                            onChange={event => setNewVetName(event.currentTarget.value)}
                            isRequired={true}
                        />
                    </Flex>
                    <Button type="button" onClick={addNewVet}>Submit</Button>
                </div>
            </>
        )
    }

    const addNewVet = () => {
        if (newVetName != null) {
            ApiService.addVet(newVetName)
                .then(response => {
                    setVets([])
                    setShowAddNewForm(false)
                })
        }
    }

    const renderUi = () => {
        return (
            <div>
                <div className={"flex justify-between m-5"}>
                    <h1>Vets</h1>
                    <Button type="button" onClick={() => setShowAddNewForm(!showAddNewForm)}>
                        {showAddNewForm ? <IoCloseCircle/> : <IoAdd/>}
                    </Button>
                </div>

                {renderVets()}
                {showAddNewForm && renderAddNewForm()}
            </div>

        )
    }

    return (
        <div>
            {vets.length === 0 ? <Loading/> : renderUi()}
        </div>
    );
}

export default ListVetsPage;
import React, {useEffect, useState} from "react";
import {Cat, IMedicalRecordEntry} from "../../api/Types";
import AddNewMedicalEvent from "./AddNewMedicalEvent";
import ApiService from "../../api/Api";
import Loading from "../../components/Loading";
import {Button} from "@aws-amplify/ui-react";
import MedicalRecordEntry from "../../components/medical/MedicalRecordEntry";
import InfoAlert from "../../components/alerts/InfoAlert";
import {FaSortAlphaDown, FaSortAlphaUp} from "react-icons/fa";

export interface IMedicalMainPageProps {
    cat: Cat
}

const MedicalMainPage = ({cat}: IMedicalMainPageProps) => {
    const [showAddNewForm, setShowAddNewForm] = useState<boolean>();
    const [loading, setLoading] = useState<boolean>(true);
    const [medicalRecords, setMedicalRecords] = useState<IMedicalRecordEntry[]>([]);
    const [sortFactor, setSortFactor] = useState<number>(-1);

    useEffect(() => {
        if (medicalRecords.length === 0 && loading) {
            ApiService.getMedicalRecords(cat.hash).then(v => {
                setMedicalRecords(v)
            }).then(() => setLoading(false))
        }

    }, [medicalRecords]);

    const renderUI = () => {
        if (loading) {
            return <Loading/>
        }

        if (showAddNewForm) {
            return <AddNewMedicalEvent cat={cat} onCancel={() => {
                setShowAddNewForm(false)
                setLoading(true)
                setMedicalRecords([])
            }}/>
        }

        if (medicalRecords.length === 0) {
            return (
                <div className={"flex flex-col"}>
                    <div className={"mb-5"}>
                        <Button onClick={() => setShowAddNewForm(true)}>Add New</Button>

                    </div>
                    <InfoAlert title={""} text={"No medical records found"}/>
                </div>
            )
        }

        medicalRecords.sort((a, b) => {
            let ts1 = new Date(a.timestamp);
            let ts2 = new Date(b.timestamp);

            let date1 = new Date(a.eventDate);
            let date2 = new Date(b.eventDate);

            if (date1 === date2) {
                return a.timestamp < b.timestamp ? -sortFactor : sortFactor;
            } else {
                return date1 < date2 ? -sortFactor : sortFactor;
            }
        })


        return (
            <div className={"flex flex-col"}>
                <div className={"mb-5 flex justify-between"}>
                    <Button onClick={() => setShowAddNewForm(true)}>Add New</Button>
                    <Button onClick={() => setSortFactor(sortFactor * -1)}>
                        {sortFactor == 1 ? <FaSortAlphaDown/> : <FaSortAlphaUp/>}
                    </Button>

                </div>
                <div>
                    {medicalRecords.map((value, index) => <MedicalRecordEntry record={value}
                                                                              key={"medical-record-" + index}/>)}
                </div>
            </div>
        )
    };


    return (
        <div>
            {renderUI()}
        </div>
    );
}

export default MedicalMainPage;
import {DEFAULT_PRICE, IExoticPet, IPrice} from "../api/Types";
import Api from "../api/Api";

const MS_DAY = 86400000;

export const toTitleCase = (input: string): string => {
    return input.replace(/([A-Z])/g, ' $1')
        .replace(/^./, (str) => str.toUpperCase())
        .trim();
}

export const toPrettyPrint = (input: string): string => {
    if (input) {
        const words = input.replaceAll("_", " ").split(" ");
        return words.map(value => value.charAt(0).toUpperCase() + value.substring(1).toLowerCase()).join(" ");
    } else {
        return "";
    }

}

/**
 * The inverse of toPrettyPrint(string): string
 */
export const toEnumString = (input: string): string => {
    return input.replaceAll(" ", "_").toUpperCase();
}

export const toAmericanDate = (date: string): string => {
    // const day = date.getDate().toString().padStart(2, '0');
    // const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
    // const year = date.getFullYear();
    // return `${month}/${day}/${year}`;
    const parts = date.split("-")
    return `${parts[1]}/${parts[2]}/${parts[0]}`
}

export const getPriceClass = (pet: IExoticPet): Promise<IPrice> => {
    const date = new Date(pet.dates.birth)
    const now = new Date()

    const diffDays = (now.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)

    return Api.getPrices().then(prices => {
        if (pet.species === "cat") {
            for (const price of prices) {
                if (diffDays <= price.max_days && diffDays >= price.min_days) {
                    return price
                }
            }
        } else if (pet.species === "rabbit") {
            const price = prices.filter(value => {
                if (value.name.startsWith("rabbit"))
                    return value;
            });
            return price[0]
        }


        return DEFAULT_PRICE;
    });

}

export const calculateYearsAndMonthsFromDate = (date: Date): string => {
    const currentDate = new Date();
    const diff = currentDate.getTime() - date.getTime();
    const diffDate = new Date(diff);

    const years = Math.floor(diffDate.getUTCFullYear() - 1970);
    const months = diffDate.getUTCMonth();
    const days = diffDate.getUTCDate();


    const totalDays = diff / 86400000
    let result = "";

    if (totalDays <= (7 * 16)) {
        result = Math.floor(diff / (MS_DAY * 7)) + " weeks"
    } else {

        if (years > 0) {
            result = `${years} years`
        }

        if (months > 0) {
            if (years > 0) result += ", "

            result += `${months} month`
            if (months > 1)
                result += "s"
        }

        if (days > 0) {
            if (months > 0) result += ", "
            result += `${days} day`

            if (days > 1)
                result += "s"
        }

    }

    return result;
}
import React, {ChangeEvent, useState} from "react";
import {Card, Flex, Input, Label, SwitchField} from "@aws-amplify/ui-react";
import SmallFieldset from "../SmallFieldset";
import GenericCard from "./GenericCard";
import {calculateYearsAndMonthsFromDate, toAmericanDate} from "../../utils/GeneralUtils";

export interface IAgeCardProps {
    birth: string; // YYYY-mm-dd
    ageGroup: string;
    onBirthdayChange: (newBirthday: Date) => void
}


const AgeCard = ({birth, ageGroup, onBirthdayChange}: IAgeCardProps) => {
    const [editAge, setEditAge] = useState<boolean>(false);
    const [birthday, setBirthday] = useState<Date>(new Date(birth))

    const inputValue = birthday.toISOString().substring(0, birthday.toISOString().indexOf('T'))

    const onAgeEdit = (evt: React.MouseEvent<HTMLParagraphElement>) => {
        setEditAge(!editAge)
    }

    const onAgeSave = () => {
        setEditAge(!editAge)
        onBirthdayChange(birthday)
    };

    const doUpdateBirthday = (input: ChangeEvent<HTMLInputElement>) => {
        setBirthday(new Date(input.currentTarget.value + "T00:00:00"))
    }

    return (
        <GenericCard title={"Age"} onEdit={onAgeEdit} onSave={onAgeSave}>
            <SmallFieldset label={"Stage"} text={ageGroup}/>
            <SmallFieldset label={"Current Age"} text={calculateYearsAndMonthsFromDate(new Date(birth))}/>
            <SmallFieldset
                label={"Date of Birth"}
                text={toAmericanDate(birth)}
                editMode={editAge}
            >
                <Input id="birthDate" name="birthDate" type="date" value={inputValue} onChange={doUpdateBirthday}
                       isRequired={true}/>
            </SmallFieldset>
        </GenericCard>
    )
}

export default AgeCard;
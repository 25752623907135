import {useActionData, useLoaderData, useMatch, useMatches} from "react-router-dom";
import {Card, Flex, Tabs, View, Image, Alert, Message} from "@aws-amplify/ui-react";
import React, {useEffect, useState} from "react";
import ApiService from "../api/Api";
import {Cat} from "../api/Types";
import Loading from "../components/Loading";
import SmallFieldset from "../components/SmallFieldset";
import CatOverviewMain from "../components/CatOverviewMain";
import AddNewMedicalEvent from "./medical/AddNewMedicalEvent";
import MedicalMainPage from "./medical/MedicalMainPage";
import {calculateYearsAndMonthsFromDate, toAmericanDate} from "../utils/GeneralUtils";
import BlueBadge from "../components/badges/BlueBadge";
import {useError} from "../components/context/ErrorContext";

const CatOverview = () => {
    const data = useMatches();
    const catId = data[0].params.catId;
    const [cat, setCat] = useState<Cat>()
    const {setError} = useError();


    useEffect(() => {
        if (catId) {
            doRefreshCat()
        }

    }, []);

    const doRefreshCat = () => {
        console.log("Refreshing cat...")
        if (catId) {
            setCat(undefined)
            ApiService.getCat(catId)
                .then((response) => {
                    setCat(response);
                }).catch((e) => {
                console.error("Failed to get cat ", e)
                setError("Failed to get cat")
            })
        }

    }

    const _renderCatLeftSide = () => {
        let imagePath = "https://resources.arc.raxware.com/no_image_available.png"
        if (cat?.image.filename)
            imagePath = "https://resources.arc.raxware.com/" + cat.image.filename

        const renderGender = () => {
            let gender = cat?.gender.toUpperCase().charAt(0);

            if (gender === "M" && cat?.spay_neuter)
                gender += "/N"
            else if (gender === "F" && cat?.spay_neuter)
                gender += "/S"

            return (
                <span>{gender}</span>
            )
        }

        return (
            <div className={"flex mb-5 w-100 "}>
                <img src={imagePath} alt={"picture of " + cat?.name} className={"w-64"}/>
                <div className={"flex flex-col px-4 pr-8 flex-grow"}>
                    <div className={"text-2xl mb-5"}>
                        <div className={"font-sans"}>{cat?.name}</div>

                        <div className={"text-sm italic text-gray-800"}>
                            {cat?.breed} {cat?.mixBreed ? "Mix" : ""}
                        </div>
                    </div>
                    <div className={"flex text-sm text-gray-800 justify-between"}>
                        <div className={"flex flex-col pr-12 "}>
                            <div className={"mb-2"}>
                                <p>DOB: {toAmericanDate(cat?.dates.birth || "")}</p>
                                <p className={"pl-4 italic text-gray-600"}>{calculateYearsAndMonthsFromDate(new Date(cat?.dates.birth || ""))}</p>
                            </div>
                            <div className={"text-sm"}>
                                {renderGender()}
                            </div>
                        </div>
                        <div className={"pr-12"}>
                            <div>Microchip</div>
                            <div>
                                {cat?.microchip.number}
                            </div>
                        </div>
                        {/*<div className={"pr-12"}>*/}
                        {/*    <div>Status</div>*/}
                        {/*    <div>*/}
                        {/*        UNKNOWN*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>

            </div>
        )
    }

    const renderUi = () => {
        if (!cat) {
            return <Message colorTheme="error">Failed to load the animal</Message>
        }

        return (
            <div className={"flex flex-col"}>
                {_renderCatLeftSide()}
                <div>
                    <Tabs.Container defaultValue={"1"}>
                        <Tabs.List spacing="equal">
                            <Tabs.Item value="1">Overview</Tabs.Item>
                            <Tabs.Item value="2">Medical</Tabs.Item>
                            <Tabs.Item value="3">Files</Tabs.Item>
                        </Tabs.List>
                        <Tabs.Panel value="1">
                            <CatOverviewMain cat={cat} onRefresh={doRefreshCat}/>
                        </Tabs.Panel>
                        <Tabs.Panel value="2">
                            <MedicalMainPage cat={cat}/>

                        </Tabs.Panel>
                        <Tabs.Panel value="3">Work in progress</Tabs.Panel>
                    </Tabs.Container>

                </div>
            </div>
        )
    }

    return (
        <Card>
            {cat == undefined ? <Loading/> : renderUi()}
        </Card>
    )
}

export default CatOverview;
import React from "react";
import {IMedicalRecordEntry} from "../../api/Types";
import BlueBadge from "../badges/BlueBadge";
import SmallFieldset from "../SmallFieldset";
import GreenBadge from "../badges/GreenBadge";
import {toTitleCase} from "../../utils/GeneralUtils";

export interface IMedicalRecordEntryComponentProps {
    record: IMedicalRecordEntry
}

const MedicalRecordEntry = ({record}: IMedicalRecordEntryComponentProps) => {

    const renderMedicalDetails = () => {
        let recordNodes = [];
        for (const key of Object.keys(record.medicalRecord)) {
            recordNodes.push(
                <SmallFieldset key={"medical-record-" + key} label={toTitleCase(key)} text={record.medicalRecord[key]}/>
            )
        }

        return (
            <div>{recordNodes}</div>
        )
    }

    const renderNotes = () => {
        if (record.notes.length === 0) {
            return null
        }
        return (
            <div
                className="p-4 my-4 border-s-4 border-gray-300 bg-gray-50 dark:border-gray-500 dark:bg-gray-800">
                <div className="text-sm italic font-medium leading-relaxed text-gray-900 dark:text-white">
                    {record.notes.split("\n").map((value, index) => {
                        return (
                            <div key={`notes-${record.timestamp}-${index}`}>
                                {value.length > 0 ? value : '\u00A0'}
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    return (
        <div className={"flex flex-col border-2 p-2 m-2"}>
            <div className={"flex justify-between w-full mb-2"}>
                <div>
                    {record.eventDate}
                </div>
                <GreenBadge text={record.medicalType}/>
            </div>
            <SmallFieldset label={"Adminstered by"} text={record.who.name || "UNKNOWN"}/>
            {renderMedicalDetails()}
            {renderNotes()}
        </div>
    );
}

export default MedicalRecordEntry;
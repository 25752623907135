import React, {ReactNode} from "react";
import {Card, Flex, Text, View} from "@aws-amplify/ui-react";

export interface ISmallFieldsetProps {
    label: string;
    text?: string;
    children?: ReactNode
    editMode?: boolean
}

const SmallFieldset = ({label, text, children, editMode}: ISmallFieldsetProps) => {

    return (
        <div className={"flex flex-col text-sm px-4 mb-4"}>
            <div className={"font-bold text-gray-700"}>{label}</div>
            {editMode ? children : <div className={"ml-2 text-gray-600"}>{text}</div>}
        </div>
    )
}

export default SmallFieldset;
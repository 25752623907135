import {Cat} from "../api/Types";
import {Badge, Card, Flex, Heading, Image, Input, Label, Text, View} from "@aws-amplify/ui-react";
import React from "react";
import {Link} from "react-router-dom";

export interface ICatCardProps {
    cat: Cat
}

const CatCard = ({cat}: ICatCardProps) => {
    const renderCatImage = () => {
        let imagePath = "https://resources.arc.raxware.com/no_image_available.png"
        if (cat.image.filename)
            imagePath = "https://resources.arc.raxware.com/" + cat.image.filename
        return (
            <View width={"18rem"}>
                <Flex direction={"row"}>
                    <img
                        src={imagePath}
                        alt=""
                        className={"max-h-20 w-20"}
                    />
                    <Text className={"flex-grow"}>{cat.name}</Text>
                </Flex>

            </View>
        )
    };
    const renderCatDetails = () => {
        // return (
        //     <View>
        //         <Flex>
        //
        //             {renderCatSummaryDetail("Breed", cat.breed)}
        //             {renderCatSummaryDetail("Colors", cat.colors.join(", "))}
        //             {renderCatSummaryDetail("Age", cat.age_group)}
        //         </Flex>
        //     </View>
        // )
        return (
            <div className={"font-sans text-gray-800 text-sm hidden md:block"}>
                <p>{cat.age_group} {cat.breed}</p>
                <p>{cat.colors.join(", ")}</p>
            </div>

        )

    };

    const renderCatSummaryDetail = (title: string, value: string) => {
        return (
            <View padding={"small"}>
                <Heading level={6}>
                    {title}
                </Heading>
                <Text>
                    {value}
                </Text>
            </View>
        )
    }

    return (
        <div className={"even:bg-gray-200 py-2 px-4 hover:bg-green-200 overflow-hidden"}>
            <Link to={`/cats/${cat.hash}`}>
                <div className={"flex justify-between"}>
                    {renderCatImage()}
                    {renderCatDetails()}
                </div>
            </Link>
        </div>
    )
}

export default CatCard
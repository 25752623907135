import React from "react";

export interface IFleaPrevtativeSubSectionProps {
    onChange: (medicalRecord: any) => void;
    value: any;
}

const FleaPrevtativeSubSection = ({value, onChange}: IFleaPrevtativeSubSectionProps) => {
    return (
        <div className="mb-5">
            <label htmlFor="fleaPreventName" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Flea Preventative Name
            </label>
            <input type="text" id="fleaPreventName"
                   value={value.name}
                   className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                   onChange={event => onChange({name: event.currentTarget.value})}
                   required/>
        </div>
    );
}

export default FleaPrevtativeSubSection;
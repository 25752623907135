import React, {useEffect, useState} from "react";
import {Cat, IExoticPet, IPrice} from "../../api/Types";
import GenericCard from "./GenericCard";
import SmallFieldset from "../SmallFieldset";
import {getPriceClass} from "../../utils/GeneralUtils";

export interface IPriceCardProps {
    animal: IExoticPet
}

const PriceCard = ({animal}: IPriceCardProps) => {
    const [priceClass, setPriceClass] = useState<IPrice>();

    useEffect(() => {
        getPriceClass(animal).then(value => {
            setPriceClass(value);
        })
    }, []);

    const renderPrice = () => {
        if (priceClass) {
            return priceClass.name + " ( $" + priceClass.dollars + " )"
        } else {
            return "?"
        }
    }

    return (
        <GenericCard title={"PRICE"}>
            <SmallFieldset label={"Class"} text={renderPrice()}/>
        </GenericCard>
    );
}

export default PriceCard;
import ReactDOM from 'react-dom/client'
import "./styles/tailwind.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import React from 'react';


import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import CatSummary from "./pages/CatSummary";
import AddNewCat from "./pages/AddNewCat";
import CatOverview from "./pages/CatOverview";
import ListSheltersPage from "./pages/shelters/ListSheltersPage";
import ListVetsPage from "./pages/vets/ListVetsPage";
import Calculator from "./pages/Calculator";
import Home from "./pages/Home";
import {ErrorProvider} from "./components/context/ErrorContext";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        children: [
            {
                index: true,
                element: <Home/>
            },
            {
                path: "/shelters",
                element: <ListSheltersPage/>,
            },
            {
                path: "/vets",
                element: <ListVetsPage/>,
            },
            {
                path: "/cats",
                element: <CatSummary/>,
            },
            {
                path: "/cats/add",
                element: <AddNewCat/>
            },
            {
                path: "/cats/:catId",
                element: <CatOverview/>
            },
            {
                path: "/calc",
                element: <Calculator/>
            }
        ]
    }
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
    <ErrorProvider>
        <RouterProvider router={router}/>
    </ErrorProvider>
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React from "react";
import {IExoticPet} from "../../api/Types";
import GenericCard from "./GenericCard";
import SmallFieldset from "../SmallFieldset";
import {toPrettyPrint} from "../../utils/GeneralUtils";

export interface IPetAdoptionStatusCardProps {
    animal: IExoticPet
}

const PetAdoptionStatusCard = ({animal}: IPetAdoptionStatusCardProps) => {
    console.log(animal)
    return (
        <GenericCard title={"Adoption Status"}>
            <SmallFieldset label={""} text={toPrettyPrint(animal.adoption_status)}/>
        </GenericCard>
    );
}

export default PetAdoptionStatusCard;
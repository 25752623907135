import React, {useEffect, useState} from "react";
import {Flex, SelectField} from "@aws-amplify/ui-react";
import {IShelter} from "../../api/Types";
import ApiService from "../../api/Api";

export interface IShelterDropdownProps {
    label?: string
    onChange: (shelter: IShelter) => void
}

const ShelterDropdown = ({label, onChange}: IShelterDropdownProps) => {
    const [shelters, setShelters] = useState<IShelter[]>([]);

    useEffect(() => {
        if (shelters.length === 0) {
            ApiService.getShelters().then(value => setShelters(value))
        }
    }, []);

    const onShelterChange = (evt: React.ChangeEvent<HTMLSelectElement>) => {
        const shelter = shelters.filter(value => value.id === evt.currentTarget.value)
        if (shelter.length === 1)
            onChange(shelter[0])
    }

    return (
        <Flex direction="column" gap="small">
            <SelectField
                label={label || "Source"}
                onChange={onShelterChange}
            >
                {shelters.map(shelter => {
                    return (
                        <option value={shelter.id}>{shelter.name}</option>
                    )
                })}
            </SelectField>
        </Flex>
    )
}

export default ShelterDropdown;
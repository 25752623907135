import {Cat} from "../api/Types";
import React, {useEffect, useState} from "react";
import CatCard from "../components/CatCard";
import ApiService from "../api/Api";
import Loading from "../components/Loading";


export interface ICatSummaryProps {
}

const CatSummary = () => {
    const [cats, setCats] = useState<Cat[] | undefined>()

    useEffect(() => {
        ApiService.getCats().then(value => {
            setCats(value)
        })
    }, [])

    const renderCats = () => {
        if (typeof cats === "undefined") {
            return <Loading/>
        } else if (cats.length === 0) {
            return <div>No cats found</div>
        } else {
            return (
                <div className={"mx-0 my-4 even:bg-green-200"}>
                    {cats.map((item, index) => {
                        return <CatCard key={item.hash} cat={item}/>;
                    })}
                </div>
            )
        }
    }

    return renderCats()
}

export default CatSummary
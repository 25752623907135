import React, {useEffect, useState} from "react";

export interface ICalculatorProps {

}

/*
clavamox

animal weight
  1.8lbs

medicine concentration
  mg / mL
  ie: 200mg / 5mL

how many mg / lb
  0.2mL / lb
==
0.4 mL to give the cat

clavamox dosage
6.25mg / lb

*/
const Calculator = () => {

    const [weightLb, setWeightLb] = useState<number>(0);
    const [amountToGive, setAmountToGive] = useState<number>(0);

    const calculate = (evt: React.FormEvent<HTMLFormElement>) => {

        evt.preventDefault();
        console.log(evt);

        const kg = parseFloat((document.getElementById("weightKg") as HTMLInputElement).value)
        setWeightLb(kg * 2.20462)

        const concentrationMg = parseFloat((document.getElementById("medicineConcentrationMg") as HTMLInputElement).value)
        const concentrationMl = parseFloat((document.getElementById("medicineConcentrationMl") as HTMLInputElement).value)

        const dosageMg = parseFloat((document.getElementById("dosageConcentrationMg") as HTMLInputElement).value)
        const dosageLb = parseFloat((document.getElementById("dosageConcentrationLb") as HTMLInputElement).value)

        // 1 mL gives us this much medicine
        const medicineConcentrationPerMl = concentrationMg / concentrationMl;
        console.log("medicine per ml", medicineConcentrationPerMl)
        // How much total mg does the animal need based on its weight and
        // dosage
        const dosageMgPerLb = dosageMg / dosageLb
        console.log(dosageMgPerLb)
        const totalDosageMg = dosageMgPerLb * weightLb;
        console.log(totalDosageMg)


        setAmountToGive(totalDosageMg / medicineConcentrationPerMl)
    };
    return (
        <div>
            <form className="max-w-sm mx-auto" onSubmit={calculate}>
                <div className="mb-5">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Weight (kg)
                    </label>
                    <input type="text"
                           id={"weightKg"}
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                           required/>
                </div>

                <div className="mb-5">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Weight (lb)
                    </label>
                    <input type="text"
                           disabled={true}
                           value={weightLb}
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                           required/>
                </div>

                <div className="mb-5">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Medicine Concentration (mg / mL)
                    </label>
                    <div className={"flex"}>
                        <input type="text"
                               id={"medicineConcentrationMg"}
                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                               required/>

                        <input type="text"
                               id={"medicineConcentrationMl"}
                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                               required/>
                    </div>

                </div>

                <div className="mb-5">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Dosage (mg / lb)
                    </label>
                    <div className={"flex"}>
                        <input type="text"
                               id={"dosageConcentrationMg"}
                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                               required/>

                        <input type="text"
                               id={"dosageConcentrationLb"}
                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                               required/>
                    </div>

                </div>

                <div className="mb-5">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Amount To Give (mL)
                    </label>
                    <input type="text"
                           disabled={true}
                           value={amountToGive}
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                </div>

                <button type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                    Calculate
                </button>
            </form>
        </div>
    );
}

export default Calculator;
import React from "react";
import {Flex, SelectField} from "@aws-amplify/ui-react";
import {toPrettyPrint} from "../../utils/GeneralUtils";

export interface IAdoptionStatusDropdownProps {
    label?: string;
    onStatusChange: (newStatus: string) => void
}

const ADOPTION_STATUS_OPTIONS = [
    'PENDING',
    'ADOPTABLE',
    'QUARENTINED',
    'PENDING_TRANSFER',
    'PENDING_ADOPTION',
    'MEDICAL_HOLD',
    'ADOPTED'
]

const AdoptionStatusDropdown = ({label, onStatusChange}: IAdoptionStatusDropdownProps) => {

    return (
        <Flex direction="column" gap="small">
            <SelectField
                label={label || "Adoption Status"}
                onChange={evt => onStatusChange(evt.currentTarget.value)}
                options={ADOPTION_STATUS_OPTIONS.map(value => toPrettyPrint(value))}
            />

        </Flex>

    );
}

export default AdoptionStatusDropdown;
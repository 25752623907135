import {Card} from "@aws-amplify/ui-react";
import {Cat} from "../api/Types";
import AgeCard from "./cards/AgeCard";
import PetIntakeStatusCard from "./cards/PetIntakeStatusCard";
import PriceCard from "./cards/PriceCard";
import PetAdoptionStatusCard from "./cards/PetAdoptionStatusCard";
import ApiService from "../api/Api";
import Loading from "./Loading";
import {useState} from "react";

export interface ICatOverviewMainProps {
    cat: Cat;
    onRefresh?: () => void
}

const CatOverviewMain = ({cat, onRefresh}: ICatOverviewMainProps) => {
    const [updating, setUpdating] = useState<boolean>(false);

    const doBirthdayUpdate = (newBirthday: Date) => {
        const newCat = {...cat}
        newCat.dates.birth = newBirthday.toISOString().substring(0, newBirthday.toISOString().indexOf('T'))

        ApiService.addCat(newCat).then(value => {
            console.log("cat updated", value)
        }).finally(() => {
            setUpdating(false)
        })
    }

    const showLoading = () => {
        return <Loading text={"Saving..."}/>
    }

    const mainUi = () => (
        <div className={"flex w-full gap-4"}>
            <Card width={"100%"}>
                <AgeCard birth={cat.dates.birth} ageGroup={cat.age_group} onBirthdayChange={doBirthdayUpdate}/>
                <PetIntakeStatusCard animal={cat}/>
            </Card>
            <Card width={"100%"}>
                <PriceCard animal={cat}/>
                <PetAdoptionStatusCard animal={cat}/>
            </Card>
        </div>
    )

    return updating ? showLoading() : mainUi()
}

export default CatOverviewMain;
import React, {useState} from "react";
import {Button, Flex, Divider, Input, Label, SelectField, SwitchField, TextAreaField} from "@aws-amplify/ui-react";
import FileDropZone from "../components/FileDropZone";
import Api from "../api/Api";
// import {IShelter} from "../api/Types";
// import ApiService from "../api/Api";
import StatusFieldset, {IStatusUpdate} from "../components/fieldsets/StatusFieldset";
import AdoptionStatusDropdown from "../components/fieldsets/AdoptionStatusDropdown";
import {toEnumString} from "../utils/GeneralUtils";
import DangerAlert from "../components/alerts/DangerAlert";
import {useNavigate} from "react-router-dom";

const BREEDS = [
    "Domestic Short Hair",
    "Domestic Medium Hair",
    "Domestic Long Hair",
    "Siamese",
    "Ragdoll",
    "Persian"
]

const COLORS = [
    "White", "Black", "Gray", "Orange"
]

const PATTERN = [
    "Calico", "Tabby", "Tortie", "Seal Point", "Solid"
]

// const AGE_GROUPS = [
//     "Newborn", // up to 8 weeks
//     "Kitten",  // up to 6 months
//     "Adult",  // up to 7 years
//     "Senior"  // 7 years +
// ]

// const STATUS = [
//     "Pending",
//     "Adoptable",
//     "Training",
//     "Quarantined",
//     "Sanctuary",
//     "Pending Transfer",
//     "Pending Adoption",
//     "Reclaimed",
//     "Medical Hold"
// ]

const DAY = 1000 * 60 * 60 * 24;
const NEWBORN_AGE_MAX = DAY * 7 * 8; // 8 Weeks
const KITTEN_AGE_MAX = DAY * 180; // 6 Months
const ADULT_AGE_MAX = DAY * 365 * 7;
const SENIOR_AGE_MAX = Number.MAX_VALUE;
const AddNewCat = () => {
    const [files, setFiles] = useState<any>({});
//    const [shelters, setShelters] = useState<IShelter[]>([]);
    const [statusUpdate, setStatusUpdate] = useState<IStatusUpdate>();
    const [adoptionStatus, setAdoptionStatus] = useState<string>("UNKNOWN");
    const [error, setError] = useState<string>("");
    const navigate = useNavigate();

    // useEffect(() => {
    //     ApiService.getShelters().then(value => setShelters(value))
    // }, []);

    const getInputValue = (evt: React.FormEvent<HTMLFormElement>, fieldName: string): string => {
        const namedItem = evt.currentTarget.elements.namedItem(fieldName);
        if (namedItem instanceof HTMLInputElement || namedItem instanceof HTMLSelectElement || namedItem instanceof HTMLTextAreaElement)
            return (namedItem as HTMLInputElement).value;
        else {
            return "Working on it 2"
        }
    }

    const getInputSwitchValue = (evt: React.FormEvent<HTMLFormElement>, fieldName: string): boolean => {
        return (evt.currentTarget.elements.namedItem(fieldName) as HTMLInputElement).checked;
    }


    const getAgeGroup = (d: Date) => {
        const now = new Date();
        const ms = now.getTime() - d.getTime();

        if (ms <= NEWBORN_AGE_MAX)
            return "Newborn";
        else if (ms > NEWBORN_AGE_MAX && ms <= KITTEN_AGE_MAX) {
            return "Kitten";
        } else if (ms > KITTEN_AGE_MAX && ms <= ADULT_AGE_MAX) {
            return "Adult";
        } else if (ms > ADULT_AGE_MAX && ms <= SENIOR_AGE_MAX) {
            return "Senior";
        } else {
            return "Unknown";
        }
    };

    const renderErrors = () => {
        if (error.length === 0) {
            return <></>
        }

        return <div>
            <DangerAlert text={error} title={"ERROR"}/>
        </div>
    };

    const convertBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };
    const doAddCat = (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();

        const colorOptions = (evt.currentTarget.elements.namedItem("color") as HTMLSelectElement).selectedOptions;
        let colors = [];
        for (let i = 0; i < colorOptions.length; i++) {
            colors.push(colorOptions[i].value)
        }

        const newCat = {
            "name": getInputValue(evt, "name"),
            "species": "cat",
            "adoption_status": adoptionStatus,
            "intake_notes": getInputValue(evt, "intake_notes"),
            "breed": getInputValue(evt, "breed"),
            "mixBreed": getInputSwitchValue(evt, "mix"),
            "colors": colors,
            "pattern": getInputValue(evt, "pattern"),
            "source": statusUpdate,
            "dates": {
                "intake": getInputValue(evt, "intakeDate"),
                "birth": getInputValue(evt, "birthDate")
            },
            "age_estimate": getInputSwitchValue(evt, "age_estimate"),
            "gender": getInputValue(evt, "gender"),
            "spay_neuter": getInputSwitchValue(evt, "cat_altered"),
            "age_group": getAgeGroup(new Date(getInputValue(evt, "birthDate"))),
            "condition": getInputValue(evt, "condition"),
            "microchip": {
                "type": "NONE",
                "number": getInputValue(evt, "microchip"),
            },
            "rabies": "NONE",
            "image": files
        };

        Api.addCat(newCat).then(value => {
            console.log(value);
            navigate("/cats");
        }).catch(reason => {
            console.error(reason);
            setError(reason)
        })

    }

    const addFile = (file: File[]) => {

        convertBase64(file[0]).then(value => {
            setFiles({
                filename: file[0].name,
                type: file[0].type,
                b64: value
            })
        });
    };
    // @ts-ignore
    return (
        <Flex as="form" id={"addCatForm"} direction="column" width="100%" style={{padding: "2rem"}}
              onSubmit={e => doAddCat(e)}>

            {renderErrors()}
            <Divider label="General Information"/>

            <Flex direction="column" gap="small">
                <Label htmlFor="name">
                    Name
                </Label>
                <Input id="name" name="name" type="text" isRequired={true}/>
            </Flex>

            <Flex direction="column" gap="small">
                <SelectField
                    options={BREEDS}
                    id={"breed"}
                    name={"breed"}
                    label={"Breed"}></SelectField>
                <SwitchField name={"mix"} label={"Mix"}/>
            </Flex>

            <Flex direction="column" gap="small">
                <SelectField
                    name={"gender"}
                    options={["Male", "Female"]}
                    label={"Gender"}></SelectField>
            </Flex>

            <Flex direction="column" gap="small">
                <SelectField
                    options={COLORS}
                    isMultiple={true}
                    isRequired={true}
                    selectSize={COLORS.length}
                    name={"color"}
                    label={"Color"}></SelectField>
            </Flex>

            <Flex direction="column" gap="small">
                <SelectField
                    name={"pattern"}
                    options={PATTERN}
                    label={"Pattern"}></SelectField>
            </Flex>

            <Flex direction="column" gap="small">
                <Label htmlFor="birthDate">
                    Birth Date
                </Label>
                <Input id="birthDate" name="birthDate" type="date" isRequired={true}/>
                <SwitchField name={"age_estimate"} label={"Estimate?"}/>
            </Flex>

            <Divider label="Intake Details"/>

            <Flex direction="column" gap="small">
                <Label htmlFor="intakeDate">
                    Intake
                </Label>
                <Input id="intakeDate" name="intakeDate" type="date" isRequired={true}/>
            </Flex>

            <Flex direction="column" gap="small">
                <Label htmlFor="intake_notes">
                    Intake Notes
                </Label>
                <TextAreaField id="intake_notes" name="intake_notes" rows={3} label={""}/>
            </Flex>

            <StatusFieldset onChange={status => setStatusUpdate(status)}/>

            <Flex direction="column" gap="small">
                <Label htmlFor="condition">
                    Condition
                </Label>
                <Input id="condition" name="condition" type="text" defaultValue={"HEALTHY"} isRequired={true}/>
                <SwitchField name={"cat_altered"} label={"Altered?"}/>
            </Flex>

            <AdoptionStatusDropdown onStatusChange={newStatus => setAdoptionStatus(toEnumString(newStatus))}/>

            <Flex direction="column" gap="small">
                <Label htmlFor="microchip">
                    Microchip
                </Label>
                <Input id="microchip" name="microchip" type="text" defaultValue={"-"}/>
            </Flex>

            <Flex direction="column" gap="small">
                <Label htmlFor="condition">
                    Picture
                </Label>
                <FileDropZone onFileUploaded={file => addFile(file)}/>
            </Flex>


            <Button type="submit" id={"submitButton"}>Submit</Button>
        </Flex>
    )
}

export default AddNewCat;
import React, {useEffect, useState} from "react";
import {Cat, IVet, IMedicalRecordEntry} from "../../api/Types";
import VaccinationSubSection from "./VaccinationSubSection";
import FleaPreventativeSubSection from "./FleaPreventativeSubSection";
import DewormingTreatmentSubSection from "./DewormingTreatmentSubSection";
import SurgerySubSection from "./SurgerySubSection";
import LabTestingSubSection from "./LabTestingSubSection";
import MedicationSubSection from "./MedicationSubSection";
import Loading from "../../components/Loading";
import {CgSpinner} from "react-icons/cg";
import LoadingIcon from "../../components/LoadingIcon";
import ApiService from "../../api/Api";
import DangerAlert from "../../components/alerts/DangerAlert";
import {toTitleCase} from "../../utils/GeneralUtils";


export interface IAddNewMedicalEvent {
    cat: Cat;
    onCancel: () => void
}

const MEDICAL_TYPES: string[] = [
    "Select Event Type",
    "VACCINATION",
    "FLEA_PREVENTATIVE",
    "DEWORMING_TREATMENT",
    "SURGERY",
    "LAB_TESTING",
    "MEDICATION"
]


const AddNewMedicalEvent = ({cat, onCancel}: IAddNewMedicalEvent) => {

    const defaultToday = () => new Date().toLocaleDateString('en-CA');
    const [medicalData, setMedicalData] = useState<any>({
        medicalType: "UNKNOWN",
        eventDate: defaultToday(),
        medicalRecord: {},
        who: {id: "", name: ""},
        notes: "",
        allRecords: []
    });
    const [vets, setVets] = useState<IVet[]>();
    const [loading, setLoading] = useState<boolean>(true);
    const [errors, setErrors] = useState<string>("");

    useEffect(() => {
        ApiService.getVets().then(value => {
            setVets(value)
            setLoading(false)
        })
    }, []);

    const renderSubSection = () => {
        switch (medicalData.medicalType) {
            case "VACCINATION":
                return <VaccinationSubSection
                    onChange={medicalRecord => setMedicalData({...medicalData, medicalRecord})}
                    value={medicalData.medicalRecord}
                />;

            case "FLEA_PREVENTATIVE":
                return <FleaPreventativeSubSection
                    onChange={medicalRecord => setMedicalData({...medicalData, medicalRecord})}
                    value={medicalData.medicalRecord}
                />

            case "DEWORMING_TREATMENT":
                return <DewormingTreatmentSubSection
                    onChange={medicalRecord => setMedicalData({...medicalData, medicalRecord})}
                    value={medicalData.medicalRecord}
                />
            case "SURGERY":
                return <SurgerySubSection
                    onChange={medicalRecord => setMedicalData({...medicalData, medicalRecord})}
                    value={medicalData.medicalRecord.name}
                />

            case "LAB_TESTING":
                return <LabTestingSubSection
                    onChange={medicalRecord => setMedicalData({...medicalData, medicalRecord})}
                    value={medicalData.medicalRecord.name}
                />


            case "MEDICATION":
                return <MedicationSubSection
                    onChange={medicalRecord => setMedicalData({...medicalData, medicalRecord})}
                    value={medicalData.medicalRecord.name}
                />


            default:
                return <div className={"mb-5"}>Select Event Type</div>
        }
    };
    const addMedicalEvent = () => {

    };

    const renderVetsDropdown = () => {
        if (!vets || vets.length === 0) {
            return <LoadingIcon/>
        }
        return (
            <select id="who"
                    value={medicalData.who.id}
                    onChange={e => {
                        setMedicalData({
                            ...medicalData,
                            who: {
                                id: e.currentTarget.value,
                                name: e.currentTarget.options[e.currentTarget.selectedIndex].innerText
                            }
                        })
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required>
                <option value={"UNKNOWN"}>Select Vet</option>
                {vets?.map((value, index) => <option value={value.id} key={"vet-" + index}>{value.name}</option>)}
            </select>
        )
    }

    const doAddMedicalEvent = async (evt: React.FormEvent<HTMLFormElement>) => {
        evt.preventDefault();

        setErrors("")
        setLoading(true)

        const additionalRecords = medicalData.allRecords
        if (additionalRecords.length > 0) {
            for (let i = 0; i < additionalRecords.length; i++) {
                const thisRecord = additionalRecords[i]
                const response = await ApiService.addMedicalRecord(cat.hash, {
                    ...thisRecord,
                    timestamp: new Date().getTime()
                });
                //console.log(response);
            }
        }

        const newRecord = medicalData;
        delete newRecord["allRecords"];

        ApiService.addMedicalRecord(cat.hash, {...newRecord, timestamp: new Date().getTime()})
            .then(value => {
                setLoading(false)
                onCancel();
            })
    };

    const renderErrors = () => {
        if (errors.length == 0) {
            return <></>
        }

        return <div>
            <DangerAlert text={errors} title={"ERROR"}/>
        </div>
    };

    /**
     * Saves an individual medical record and resets the form
     * so another one can be entered.
     */
    const doSaveEvent = () => {
        let newErrors: string[] = [];

        if (medicalData.who.id === "") {
            newErrors.push("Missing vet")
        }

        if (medicalData.medicalType === "UNKNOWN") {
            newErrors.push("Missing medical event type")
        }

        if (Object.keys(medicalData.medicalRecord).length === 0) {
            newErrors.push("Missing medical record details")
        }

        if (newErrors.length > 0) {
            setErrors(newErrors[0])
        } else {
            const copy = {...medicalData};
            delete copy["allRecords"]

            setMedicalData({
                medicalType: "UNKNOWN",
                eventDate: medicalData.eventDate,
                medicalRecord: {},
                who: {id: "UNKNOWN", name: ""},
                notes: "",
                allRecords: [...medicalData.allRecords, copy]
            })
        }
    };
    const renderPendingRecords = () => {
        return (
            <>
                <div className={"w-1/2 pl-4"}>
                    {medicalData.allRecords.map((val: any, index: number) => {
                        console.log(val);
                        return (
                            <>
                                <hr key={`pending-medical-sep-${index}`} className={"mt-5 mb-5"}/>
                                <div key={`pending-medical-${index}`} className={"text-sm"}>
                                    <p>{val.medicalType}</p>
                                    <div className={"flex "}>
                                        <div className={"p-2"}>
                                            <p>Date</p>
                                            <p className={"pl-2"}>{val.eventDate}</p>
                                        </div>
                                        {Object.keys(val.medicalRecord).map(value => {
                                            return (
                                                <div className={"p-2"}>
                                                    <p>{toTitleCase(value)}</p>
                                                    <p className={"pl-2"}>{val.medicalRecord[value]}</p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <p className={"text-sm p-2"}>{val.notes}</p>
                                </div>
                            </>

                        )
                    })}
                </div>
            </>
        )
    };
    const renderNoRecords = () => {
        return (
            <div className={"w-1/2 pl-4"}>
                <hr className={"mt-5 mb-5"}/>
                <div>
                    No records to add
                </div>
            </div>
        )
    };
    const renderNewForm = () => {
        return (
            <form className="w-1/2" onSubmit={doAddMedicalEvent}>
                {renderErrors()}
                <div className="mb-5">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Date of Event
                    </label>
                    <input type="date" id="email"
                           value={medicalData.eventDate}
                           onChange={event => setMedicalData({...medicalData, eventDate: event.currentTarget.value})}
                           className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                           required/>
                </div>
                <hr className={"mb-5"}/>
                <div className="mb-5">
                    <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Who Administered
                    </label>
                    {renderVetsDropdown()}
                </div>
                <div className="mb-5">
                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Medical Event Type
                    </label>
                    <select id="medical-type"
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            onChange={event => setMedicalData({...medicalData, medicalType: event.currentTarget.value})}
                            value={medicalData.medicalType}
                            required>
                        {MEDICAL_TYPES.map((value, index) => <option key={"medical-type-" + index}>{value}</option>)}
                    </select>
                </div>
                {renderSubSection()}
                <div className="mb-5">
                    <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Notes
                    </label>
                    <textarea id="notes"
                              rows={7}
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              onChange={event => setMedicalData({...medicalData, notes: event.currentTarget.value})}
                              value={medicalData.notes}
                    >

                    </textarea>
                </div>
                <button type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={addMedicalEvent}
                >
                    Submit Record
                </button>
                <button type="button"
                        className="text-white bg-blue-700 ml-5 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={doSaveEvent}
                >
                    Add To Record
                </button>

                <button type="button"
                        className="text-blue-700 ring-1 ml-5 bg-white hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        onClick={onCancel}
                >
                    Cancel
                </button>
            </form>
        )
    };
    const mainUi = () => (
        <div className={"flex"}>
            {renderNewForm()}
            {medicalData.allRecords.length === 0 ? renderNoRecords() : renderPendingRecords()}
        </div>
    );

    return loading ? <Loading/> : mainUi()

}

export default AddNewMedicalEvent;